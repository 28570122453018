<template>
    <!-- Begin Footer Area -->
    <video-background
    src="images/footer/section-footer.mp4"
    class="area desktop"
    object-fit="fill"
    >
        <footer>
            <div class="footer-top">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-5 col-lg-4">
                            <div class="footer-item">
                                <router-link to="/" class="footer-logo"><img src="images/logo/riset-1.png" alt="Logo"></router-link>
                                <p class="footer-desc">PT Riset Kecerdasan Buatan (“RKB”) is a company that focuses on the research and development of AI solutions (Computer Vision) for a wide range of industries that are adapting to Indonesia’s rapidly digitalizing economy.</p>
                                <!-- <div class="footer-social-link">
                                    <ul>
                                        <li v-for="(social, index) in socials" :key="index">
                                            <a :href="social.link"><i v-bind:class="social.iconName"></i></a>
                                        </li>
                                    </ul>
                                </div> -->
                            </div>
                        </div>
                        <div class="col-xl-7 col-lg-8">
                            <div class="footer-item-wrap row">
                                <div class="col-lg-4 col-md-4 col-sm-5 pt-5 pt-lg-0">
                                    <div class="footer-item">
                                        <div class="footer-list">
                                            <h2 class="title">Navigation</h2>
                                            <ul>
                                                <li><router-link to="/about-us">About Us</router-link></li>
                                                <li><router-link to="/product">Products</router-link></li>
                                                <li><router-link to="/blog">Blog</router-link></li>
                                                <li><router-link to="/contact">Contact Us</router-link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-5 col-md-4 col-sm-5 pt-5 pt-lg-0">
                                    <div class="footer-item footer-ps-xl-30">
                                        <div class="footer-list">
                                            <h2 class="title">Other</h2>
                                            <ul>
                                                <!-- <li><a href="#" @click="openNewTab()">API Riset.ai</a></li> -->
                                                <li><a target=”_blank” href="https://risetai.gitbook.io/face-docs/">API Riset.ai</a></li>
                                                <!-- <li><router-link to="/">API Riset.ai</router-link></li> -->
                                                <!-- <li><router-link to="/">Career</router-link></li> -->
                                                <!-- <li><a href="/#partner">Partners</a></li> -->
                                                <!-- <li><router-link to="/">FAQ</router-link></li> -->
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-4 col-sm-12 pt-5 pt-lg-0">
                                    <div class="footer-item footer-ps-xl-20">
                                        <div class="footer-list">
                                            <h2 class="title">Follow Us</h2>
                                            <ul class="row">
                                                <li v-for="(social, index) in socials" :key="index" class="col-lg-12 col-sm-4">
                                                    <router-link to="/"><i v-bind:class="social.iconName"></i> {{ social.name }}</router-link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer-bottom">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="footer-bottom-item">
                                    <span>Copyright © 2022. Riset.ai Allright reserved</span>
                                <div class="footer-copyright">
                                    <span><router-link to="/terms-and-conditions">Term & Condition</router-link></span>
                                    <span><router-link to="/privacy-policy">Privacy Policy</router-link></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    </video-background>
    <video-background
    src="images/footer/section-footer-responsive.mp4"
    class="area mobile"
    object-fit="fill"
    >
        <footer>
            <div class="footer-top">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-5 col-lg-4">
                            <div class="footer-item">
                                <router-link to="/" class="footer-logo"><img src="images/logo/riset-1.png" alt="Logo"></router-link>
                                <p class="footer-desc">PT Riset Kecerdasan Buatan (“RKB”) is a company that focuses on the research and development of AI solutions (Computer Vision) for a wide range of industries that are adapting to Indonesia’s rapidly digitalizing economy.</p>
                                <!-- <div class="footer-social-link">
                                    <ul>
                                        <li v-for="(social, index) in socials" :key="index">
                                            <a :href="social.link"><i v-bind:class="social.iconName"></i></a>
                                        </li>
                                    </ul>
                                </div> -->
                            </div>
                        </div>
                        <div class="col-xl-7 col-lg-8">
                            <div class="footer-item-wrap row">
                                <div class="col-lg-4 col-md-4 col-sm-6 pt-5 pt-lg-0">
                                    <div class="footer-item">
                                        <div class="footer-list">
                                            <div class="row">
                                                <h2 class="title col-6">Navigation</h2>
                                                <button @click="navigationDropToggle" class="col-6 text-right btn text-white">
                                                    <i class="icofont-rounded-down col-1"></i>
                                                </button>
                                            </div>
                                            <ul v-if="navigationDrop === true">
                                                <li><router-link to="/about-us">About Us</router-link></li>
                                                <li><router-link to="/product">Products</router-link></li>
                                                <li><router-link to="/blog">Blog</router-link></li>
                                                <li><router-link to="/contact">Contact Us</router-link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-5 col-md-4 col-sm-6 pt-5 pt-lg-0">
                                    <div class="footer-item footer-ps-xl-30">
                                        <div class="footer-list">
                                            <div class="row">
                                                <h2 class="title col-6">Other</h2>
                                                <button @click="otherDropToggle" class="col-6 text-right btn text-white">
                                                    <i class="icofont-rounded-down col-1"></i>
                                                </button>
                                            </div>
                                            <ul v-if="otherDrop === true">
                                                <!-- <li><a href="#" @click="openNewTab()">API Riset.ai</a></li> -->
                                                <li><a target=”_blank” href="https://risetai.gitbook.io/face-docs/">API Riset.ai</a></li>
                                                <!-- <li><router-link to="/">API Riset.ai</router-link></li> -->
                                                <!-- <li><router-link to="/">Career</router-link></li> -->
                                                <!-- <li><router-link to="/#partner">Partners</router-link></li> -->
                                                <!-- <li><router-link to="/">FAQ</router-link></li> -->
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-4 col-sm-12 pt-5 pt-lg-0">
                                    <div class="footer-item footer-ps-xl-20">
                                        <div class="footer-list">
                                            <div class="row">
                                                <h2 class="title col-6">Follow Us</h2>
                                                <button @click="socmedDropToggle" class="col-6 text-right btn text-white">
                                                    <i class="icofont-rounded-down col-1"></i>
                                                </button>
                                            </div>
                                            <ul class="row" v-if="socmedDrop === true">
                                                <li v-for="(social, index) in socials" :key="index" class="col-lg-12 col-sm-4">
                                                    <router-link to="/"><i v-bind:class="social.iconName"></i> {{ social.name }}</router-link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer-bottom">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="footer-bottom-item">
                                    <span>Copyright © 2022. Riset.ai Allright reserved</span>
                                <div class="footer-copyright">
                                    <span><router-link to="/terms-and-conditions">Term & Condition</router-link></span>
                                    <span><router-link to="/privacy-policy">Privacy Policy</router-link></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    </video-background>
    <!-- Footer Area End Here -->

    <!-- <vue-iframe v-if="myIframe === true" src="https://risetai.gitbook.io/face-docs/" style="position:fixed; top:0; left:0; bottom:0; right:0; width:100%; height:100%; border:none; margin:0; padding:0; overflow:hidden; z-index:999999;">
        Your browser doesn't support iframes
    </vue-iframe> -->

    <!-- Begin Scroll To Top -->
    <back-to-top bottom="50px" right="50px">
        <button type="button" class="footer-scroll-top scroll-to-top style-1 show border-0">
            <i class="icofont-arrow-up"></i>
        </button>
    </back-to-top>
    <!-- Scroll To Top End Here -->
</template>

<script>
import VideoBackground from 'vue-responsive-video-background-player'

export default {
    components: { 
        VideoBackground
    },
    data () {
        return {
            bannerBg: 'images/banner/bg-hero.png', 
            bannerBg2: 'images/footer/ornament.png',
            myIframe: true,
            navigationDrop: false,
            otherDrop: false,
            socmedDrop: false,
            socials: [
                {
                    link: 'https://www.facebook.com',
                    iconName: 'icofont-facebook',
                    name: 'Facebook'
                },
                {
                    link: 'https://www.twitter.com',
                    iconName: 'icofont-twitter',
                    name: 'Twitter'
                },
                {
                    link: 'https://www.instagram.com',
                    iconName: 'icofont-instagram',
                    name: 'Instagram'
                }
            ]
        }
    },
    methods: {
        onLoad (iframe) {
            this.myIframe = iframe;
        },
        openNewTab() {
            // Open New Tab
            window.open('https://risetai.gitbook.io/face-docs/', '_blank');
        },
        navigationDropToggle() {
            this.navigationDrop = !this.navigationDrop;
            this.otherDrop = false;
            this.socmedDrop = false;
        },
        otherDropToggle() {
            this.navigationDrop = false;
            this.otherDrop = !this.otherDrop;
            this.socmedDrop = false;
        },
        socmedDropToggle() {
            this.navigationDrop = false;
            this.otherDrop = false;
            this.socmedDrop = !this.socmedDrop;
        }
    }
}
</script>
<style scoped>
.area{
    height: 450px;
}
.desktop {
    display: block;
}
.mobile {
    display: none;
}
.footer-scroll-top {
    background-color: #000000;
    border-radius: 50%;
    color: #ffffff;
    width: 50px;
    height: 50px;
    line-height: 40px;
    display: block;
    text-align: center;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}
.footer-top {
    /* Background Left Bottom */
    background-position: left bottom;
    /* Background No Repeat */
    background-repeat: no-repeat;
    padding-top: 60px;
    padding-bottom: 40px;
}
.footer-list h2 {
    color: #ffffff;
    font-family: 'Exo 2';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.231818px;
    margin-bottom: 10px;
}
.footer-list ul {
    font-family: 'Exo 2';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
}
.footer-list ul li a{
    font-family: 'Exo 2';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
}
.footer-list .btn {
    /* Align Right */
    text-align: right !important;
}
.footer-desc {
    font-family: "Exo 2";
    color: #ffffff;
    max-width: 400px;
    font-size: 16px;
    line-height: 35px;
    margin-bottom: 0px;
}
.footer-logo {
    margin-bottom: 10px;
    display: block;
}
.footer-bottom-item,
.footer-copyright a {
    color: #A8A8A8;
    font-family: 'Exo 2';
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    padding-right: 20px;
    padding-left: 20px;
}
.footer-copyright a {
    color: #ffffff;
}
.mobile {
    display: none;
}
@media (max-width: 991px) {
    .area{
        height: 605px;
    }
    .mobile {
        display: block;
    }
    .desktop {
        display: none;
    }
}
@media (max-width: 770px) {
    .area{
        height: 575px;
    }
    .mobile {
        display: block;
    }
    .desktop {
        display: none;
    }
}
@media (max-width: 574px) {
    .area{
        height: 775px;
    }
    .mobile {
        display: block;
    }
    .desktop {
        display: none;
    }
}
</style>
