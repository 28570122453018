<template>
    <!-- Begin Banner Area -->
    <div class="banner-area banner-style-3">
        <div class="container banner-container" :style="{backgroundImage: `url(${ bannerBg })`}" >
            <div class="row">
                <div class="col-lg-12">
                    <div class="banner-item">
                        <div class="banner-content">
                            <h2 class="banner-title exo2-font">
                                Learn all of the ways Riset.ai
                                can drive revenue growth
                                for your business.
                            </h2>
                            <div class="btn-wrap">
                                <router-link class="btn btn-custom-size btn-lg purple-color" to="/contact">Contact Us</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Banner Area End Here -->
</template>
<script>
export default {
  data () {
    return {
      bannerBg: 'images/banner/banner-6.png'
    }
  }
}
</script>
<style scoped>
.banner-area {
    padding-top: 0px;
    width: 100%;
}
.banner-style-3 .banner-item {
    border-radius: 0px;
}
.banner-style-3 .banner-content {
    max-width: 750px;
    text-align: left;
    margin-left: 10%;
}
.banner-style-3 .banner-title {
    margin-bottom: 25px;
    font-family: 'Exo 2';
    font-style: normal;
    font-weight: 800;
    font-size: 48px;
    line-height: 66px;
    /* or 138% */

    letter-spacing: 0.03em;
}
.btn.purple-color {
    background-color: #CE097C;
    color: #ffffff;
    font-family: 'Exo 2';
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    border-radius: 50px;
    padding: 1.5rem 0px;
    width: 210px;
    height: 55px;
    line-height: 0px;
}
.btn.purple-color:hover {
    background-color: transparent;
    color: #CE097C;
    border-color: #CE097C;
}
@media (max-width: 991px) {
    .banner-style-3 .banner-container {
        max-width: 100%;
        background-size: cover;
    }

    .banner-style-3 .banner-content {
        max-width: 80%;
        text-align: center;
    }

    .banner-style-3 .banner-item {
        text-align: center;
        align-items: center;
    }

    .btn.purple-color {
        background-color: #CE097C;
        color: #ffffff;
        font-family: 'Exo 2';
        font-weight: 700;
        font-size: 24px;
        width: 240px;
        height: 65px;
        padding: 28px 8px;
        line-height: 0px;
        border-radius: 32px;
    }
}
</style>
