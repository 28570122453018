<template>
    <!-- Begin Main Header Area -->
    <header class="header">
        <div class="header-area header-position-absolute header-sticky header-style-5" :class="{'is-active': isSticky}">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-xl-3 col-lg-2 col-6">
                        <router-link class="header-logo" to="/">
                            <img class="primary-img" src="images/logo/riset-1.png" alt="Header Logo">
                            <img class="sticky-img" src="images/logo/riset-2.png" alt="Header Logo">
                        </router-link>
                    </div>
                    <div class="col-lg-6 d-none d-lg-block">
                        <div class="header-menu m-auto">
                            <nav class="header-menu-nav onepage-nav">
                                <ul>
                                    <li :class="{ 'active' : $route.name === 'Home' }">
                                        <router-link to="/">Home</router-link>
                                    </li>
                                    <li :class="{ 'active' : $route.name === 'about-us' }">
                                        <router-link to="/about-us">About Us</router-link>
                                    </li>
                                    <li :class="{ 'active' : $route.name === 'product' ||  $route.name === 'product-detail'}">
                                        <router-link to="/product">Products</router-link>
                                    </li>
                                    <li :class="{ 'active' : $route.name === 'blog' ||  $route.name === 'blog-detail'}">
                                        <router-link to="/blog">Blog</router-link>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-2 col-6">
                        <ul class="header-right">
                            <!-- <li class="d-none d-md-block">
                                <router-link to="/register">Creat An Account</router-link>
                            </li> -->
                            <li class="d-none d-md-block">
                                <router-link class="btn btn-custom-size" to="/contact">Contact Us</router-link>
                            </li>
                            <li class="d-flex d-lg-none">
                                <button class="btn p-0" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBothOptions" aria-controls="offcanvasWithBothOptions">
                                    <i class="icofont-navigation-menu"></i>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="offcanvas offcanvas-start" data-bs-scroll="true" tabindex="-1" id="offcanvasWithBothOptions">
            <div class="offcanvas-header">
                <router-link to="/" class="header-logo">
                    <img src="images/logo/riset-2.png" alt="Header Logo">
                </router-link>
                <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div class="offcanvas-body">
                <div class="header-offcanvas-menu">
                    <nav class="header-offcanvas-nav onepage-offcanvas-nav">
                        <ul>
                            <li>
                                <router-link to="/">Home</router-link>
                            </li>
                            <li class="">
                                <router-link to="/about-us">About Us</router-link>
                            </li>
                            <li>
                                <router-link to="/product">Products</router-link>
                            </li>
                            <li class="">
                                <router-link to="/blog">Blog</router-link>
                            </li>
                            <li>
                                <router-link to="/contact">Contact Us</router-link>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </header>
    <!-- Main Header Area End Here -->
</template>
<script>
export default {

  data () {
    return {
      isSticky: false,
      isHidden: false
    }
  },

  mounted () {
    window.addEventListener('scroll', () => {
      const scrollPos = window.scrollY
      if (scrollPos >= 200) {
        this.isSticky = true
      } else {
        this.isSticky = false
      }
    })
  }
}

</script>

<style lang="scss" scoped>
.header{
    &.fixed-top {
        z-index: 99;
    }
}
.header-style-5 .header-menu {
    text-align: center;
}
.header-toggler {
    background: transparent;
    border: 0px;
    color: #fff;
    font-size: 26px;
    &:hover {
        color: #6e5a49;
    }
}
.header-menu-nav .active a {
    font-family: 'Exo 2';
    color: rgb(255, 255, 255);
    border-bottom: 3px solid #CE097C;
    padding-bottom: 5px;
}
.header-style-5[class*='is-active'] .header-right li a {
    font-family: 'Exo 2';
    font-style: normal;
    font-weight: 400;
    margin-bottom: 6px;
    font-size: 18px;
}
.header-menu-nav ul li {
    font-family: 'Exo 2';
    font-style: normal;
    font-weight: 400;
}
.header-offcanvas-nav ul li a {
    font-family: 'Exo 2';
    font-style: normal;
    font-weight: 400;
}
.btn-custom-size {
    font-family: 'Exo 2';
    font-style: normal;
    font-weight: 700;
    border: 1px solid white;
    background-color: transparent;
    color: black;
    font-size: 14px;
    cursor: pointer;
}
.btn-custom-size:hover {
    border: 1px solid #CE097C;
    color: #CE097C !important;
}
</style>
